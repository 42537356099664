<template>
  <div class="mt-10 max-w-4xl mx-auto">
    <table class='mx-auto max-w-4xl w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden'>
      <thead class="bg-gray-50">
        <tr class="text-gray-600 text-left">
          <th class="font-semibold text-sm uppercase px-6 py-4">
            Map
          </th>
          <th class="font-semibold text-sm uppercase px-6 py-4 text-center">
            Rollen
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200" v-if="directories">
        <tr v-for="directory in directories" :key="directory">
          <td class="px-6 py-4">
            <p class="">
              {{ directory }}
            </p>
          </td>
          <td class="px-6 py-4 text-center" v-if="accessDirectories.find(accessDirectory => accessDirectory.path === directory)">
            <span v-for="(role, index) in accessDirectories.find(accessDirectory => accessDirectory.path === directory).roles" :key="index">{{ role }}<span v-if="index < accessDirectories.find(accessDirectory => accessDirectory.path === directory).roles.length - 1">, </span></span>
          </td>
          <td class="px-6 py-4 text-center" v-if="!accessDirectories.find(accessDirectory => accessDirectory.path === directory)">
          </td>
          <td class="px-6 py-4 text-center">
            <router-link :to="'/folders/edit/' + directory" class="text-purple-800 hover:underline">Edit</router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  title: 'Folders',
  name: 'Folders',
  data () {
    return {
      directories: null,
      accessDirectories: null,
      error: null
    }
  },
  mounted () {
    if (!this.$store.getters.directories) {
      this.$store
        .dispatch('getFiles')
        .then(() => this.setFolders())
        .catch(() => this.setError('Er is iets misgegaan'))
    } else this.setFolders()
  },
  methods: {
    setFolders () {
      this.directories = this.$store.getters.rootDirectories
      this.accessDirectories = this.$store.getters.accessDirectories
    },
    setError (data) {
      this.error = data
    }
  }
}
</script>
